import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
// import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
// import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import headerImg from "../assets/images/secondgardenpondandreattawaterscapes.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
  color: white;
  padding: 8rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 20%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 20%;
    background-attachment: scroll;
  }
`

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: "2.75rem",
    fontWeight: 600,
    padding: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  h2: {
    fontSize: "3rem",
    color: theme.palette.common.muted,
    fontWeight: 600,
    [theme.breakpoints.only("xl")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
  },
  h3: {
    fontSize: "3rem",
    fontWeight: 500,
    color: theme.palette.secondary.main,
    [theme.breakpoints.only("xl")]: {},
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.25rem",
    },
  },
  body: {
    fontSize: "1.05rem",
    lineHeight: "1.75rem",
  },
  contentCtr: {
    paddingBottom: "7rem",
  },
  contentCard: {
    width: "100%",
    zIndex: 5,
    marginTop: "-7rem",
    borderRadius: 0,
    padding: "2rem 3rem",
  },
}))

export default function OutdoorLivingComponent() {
  const theme = useTheme()
  const classes = useStyles()
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  // const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))

  return (
    <>
      <Background>
        <Typography variant="h1" className={classes.h1} align="center">
          Pricing & Financing
        </Typography>
      </Background>
      <Container maxWidth="lg" className={classes.contentCtr}>
        <Paper className={classes.contentCard} elevation={0}>
          <Grid container>
            <Typography variant="h2" className={classes.h2}>
              Pricing
            </Typography>
          </Grid>
        </Paper>
      </Container>
    </>
  )
}

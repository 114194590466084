import React from "react"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"

import PricingComponent from "../components/PricingFinancingComponent"

export default function PricingPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Maintenance Services"
        description="Learn about professional maintenance services for ponds and other water features by Andreatta Waterscapes."
      />
      <PricingComponent />
    </Layout>
  )
}
